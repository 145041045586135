<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-50">
    <h2 class="text-3xl font-bold text-gray-800">注册</h2>
    <form @submit.prevent="register" class="mt-6 w-full max-w-sm">
      <input v-model="form.name" type="text" placeholder="用户名" class="mb-4 px-4 py-2 w-full border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"/>
      <input v-model="form.email" type="email" placeholder="邮箱" class="mb-4 px-4 py-2 w-full border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"/>
      <input v-model="form.password" type="password" placeholder="密码" class="mb-4 px-4 py-2 w-full border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"/>
      <input v-model="form.password_confirmation" type="password" placeholder="确认密码" class="mb-6 px-4 py-2 w-full border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"/>
      <button type="submit" class="px-6 py-3 w-full bg-green-500 text-white rounded hover:bg-green-600">注册</button>
    </form>
  </div>
</template>

<script>
import apiClient from '@/plugins/axios'; // 引入配置好的 Axios 实例

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
    };
  },
  methods: {
    async register() {
      try {
        const response = await apiClient.post('/register', this.form);
        if (response && response.data) {
          console.log('注册成功:', response.data);
          alert('注册成功');
          this.$router.push({ name: 'login' }); // 成功后跳转到登录页面
          return;  // 直接返回，避免后续代码执行
        }
      } catch (error) {
        // 错误处理部分
        if (error.response && error.response.status === 422) {
          console.error('注册失败: 邮箱已经被注册');
          alert('注册失败: 该邮箱已经被注册，请使用另一个邮箱。');
        } else {
          console.error('注册失败:', error.response ? error.response.data : error.message);
          alert('注册失败: 发生了错误，请稍后再试。');
        }
      }
    },
  },
};
</script>

<style scoped>
/* 添加一些样式以适配布局 */
</style>
