//src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import UserRegister from '@/pages/Auth/UserRegister.vue';
import UserLogin from '@/pages/Auth/UserLogin.vue';
import ChangePassword from '@/pages/Auth/ChangePassword.vue';
import HomePage from '@/pages/HomePage.vue';
import IndexPage from '@/pages/index.vue';
import UserPosition from '@/pages/UserPosition.vue';
import TradeRecordCreate from '@/pages/TradeRecords/CreateTradeRecord.vue';
import TradeRecordIndex from '@/pages/TradeRecords/IndexTradeRecord.vue';
import TradeRecordShow from '@/pages/TradeRecords/ShowTradeRecord.vue';
import UserLogout from '@/pages/Auth/UserLogout.vue';
import TradePrinciplesPage from '@/pages/TradePrinciplesPage.vue';

// 定义路由
const routes = [
  { path: '/', name: 'index', component: IndexPage },  // 网站首页
  { path: '/register', name: 'register', component: UserRegister },   // 用户注册
  { path: '/login', name: 'login', component: UserLogin },   // 用户登录
  { path: '/change-password', name: 'change-password', component: ChangePassword, meta: { requiresAuth: true } }, // 需要登录
  { path: '/home', name: 'home', component: HomePage, meta: { requiresAuth: true } },  // 需要登录
  { path: '/user-positions', name: 'user-positions', component: UserPosition, meta: { requiresAuth: true } },  // 需要登录
  { path: '/trading-principles', name: 'trading-principles', component: TradePrinciplesPage, meta: { requiresAuth: true } },  // 需要登录
  { path: '/trade-records/create', name: 'trade-record-create', component: TradeRecordCreate, meta: { requiresAuth: true } },  // 需要登录
  { path: '/trade-records', name: 'trade-record-index', component: TradeRecordIndex, meta: { requiresAuth: true } },  // 需要登录
  { path: '/trade-records/:id', name: 'trade-record-show', component: TradeRecordShow, meta: { requiresAuth: true } },  // 需要登录
  { path: '/logout', name: 'logout', component: UserLogout },
];

// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 全局导航守卫
router.beforeEach((to, from, next) => {
  // 检查路由是否需要登录
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 检查用户是否已登录
    const isAuthenticated = !!localStorage.getItem('auth_token');  // 假设使用 localStorage 存储 token

    if (!isAuthenticated) {
      // 如果未登录，跳转到登录页面
      next({ name: 'login' });
    } else {
      // 已登录，继续导航
      next();
    }
  } else {
    // 不需要登录，直接继续导航
    next();
  }
});

export default router;
