<template>
  <div class="flex justify-center items-center min-h-screen bg-gray-100">
    <div class="bg-white p-16 rounded-lg shadow-lg max-w-2xl w-full text-center">
      <h1 class="text-5xl font-bold text-gray-900 mb-8">欢迎来到 My Trading Strategy</h1>
      <h2 class="text-2xl text-gray-700 leading-relaxed mb-8">
        市场从来不缺韭菜，不想做韭菜的唯一途径就是成为职业交易者，
        交易有策略、有计划、有反思、有总结
      </h2>
      <p class="text-gray-600 leading-relaxed mb-6">
        我们构造了一套基于看涨买入模型的交易策略，韭菜和职业交易者最大的差异就是，
        职业交易者在股票上涨中买入，在股票下跌过程中卖出
      </p>
      <p class="text-gray-600 leading-relaxed mb-6">
        如果你希望在股市有所收获，就跟我们一起行动起来吧！
      </p>
      <p class="text-gray-600 leading-relaxed mb-10">请选择以下操作,开始你的交易旅程</p>
      <div class="flex justify-center space-x-8">
        <router-link to="/login" class="px-8 py-4 bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-600 transition ease-in-out duration-150">
          登录
        </router-link>
        <router-link to="/register" class="px-8 py-4 bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-600 transition ease-in-out duration-150">
          注册
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexPage',
};
</script>
