<template>
  <AppLayout title="交易详情">
    <template #header>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">交易详情</h2>
    </template>

    <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg p-6" v-if="tradeRecord && tradeRecord.stock">

          <!-- 第1层：股票信息和操作按钮 -->
          <div class="flex justify-between items-center mb-4">
            <h4 class="text-lg font-semibold text-gray-800">
              {{ tradeRecord.stock?.name || '未知股票' }} ({{ tradeRecord.stock?.symbol || '未知代码' }})
            </h4>
          </div>
          <!-- 第3层：基础信息和盈亏信息 -->
          <div class="flex">
            <!-- 3.1层：基础信息展示 -->
            <div class="flex-1 space-y-4">
              <div class="grid grid-cols-4 gap-4">
                <div class="flex flex-col">
                  <span class="text-gray-600">开仓日期</span>
                  <span class="font-semibold text-gray-800">{{ tradeRecord.opening_date || '未知日期' }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-gray-600">开仓价格</span>
                  <span class="font-semibold text-gray-800">{{ tradeRecord.initial_opening_price || '未知价格' }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-gray-600">止损价格</span>
                  <span class="font-semibold text-gray-800">{{ tradeRecord.initial_stop_loss_price || '未知价格' }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-gray-600">止盈价格</span>
                  <span class="font-semibold text-gray-800">{{ tradeRecord.initial_target_profit_price || '未知价格' }}</span>
                </div>
              </div>
              <div class="grid grid-cols-4 gap-4">
                <div class="flex flex-col">
                  <span class="text-gray-600">盈亏比</span>
                  <span class="font-semibold text-gray-800">{{ tradeRecord.risk_reward_ratio || '尚未计算' }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-gray-600">持仓成本</span>
                  <span class="font-semibold text-gray-800">{{ tradeRecord.holding_price !== undefined ? tradeRecord.holding_price : '未知价格' }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-gray-600">持股数量</span>
                  <span class="font-semibold text-gray-800">{{ tradeRecord.holding_shares !== undefined ? tradeRecord.holding_shares : '未知数量' }}</span>
                </div>
                <div class="flex flex-col">
                  <span class="text-gray-600">持仓市值</span>
                  <span class="font-semibold text-gray-800">{{ tradeRecord.holding_value !== undefined ? tradeRecord.holding_value : '未知金额' }}</span>
                </div>
              </div>
            </div>

            <!-- 3.2层：盈亏信息展示 -->
            <div class="flex items-center justify-center bg-green-100 p-4 ml-4 w-32 h-full rounded-lg shadow-md">
              <span class="text-xl font-semibold" :class="tradeRecord.final_profit < 0 ? 'text-red-600' : 'text-green-600'">
                {{ tradeRecord.final_profit || '尚未确定' }}
              </span>
            </div>
          </div>

          <!-- 交易调整记录展示 -->
          <div class="mt-6">
            <h4 class="text-lg font-bold mb-4">交易调整记录</h4>

            <div v-if="loadingAdjustments">
              <p class="text-gray-500">加载中，请稍候...</p>
            </div>

            <div v-else-if="adjustments && adjustments.length > 0">
              <div v-for="adjustment in adjustments" :key="adjustment.id" 
                   :class="adjustment.trade_type === 1 ? 'bg-green-100' : 'bg-red-100'" 
                   class="border border-gray-300 rounded-md p-4 mb-4 shadow">

                <!-- 调整信息横排展示 -->
                <div class="flex justify-between items-center space-x-4">
                  <p class="text-sm font-medium text-gray-800">调整价格: {{ adjustment.trade_price }}</p>
                  <p class="text-sm font-medium text-gray-800">调整数量: {{ parseFloat(adjustment.amount).toFixed(0) }}</p>
                  <p class="text-sm font-bold text-indigo-600 text-lg">总调整金额: {{ adjustment.trade_price * adjustment.amount }}</p>
                  <p class="text-sm font-medium text-gray-800">调整日期: {{ adjustment.adjustment_date }}</p>
                </div>

                <!-- 调整原因和编辑/删除按钮 -->
                <div class="flex justify-between items-center mt-2">
                  <p class="text-sm text-gray-700">{{ adjustment.adjustment_reason }}</p>
                  <div class="flex space-x-4">
                    <button 
                      @click="editAdjustment(adjustment)" 
                      class="bg-yellow-300 hover:bg-yellow-400 text-white text-sm px-4 py-2 rounded shadow"
                    >
                      编辑
                    </button>
                    <button 
                      @click="deleteAdjustment(adjustment.id)" 
                      class="bg-red-300 hover:bg-red-400 text-white text-sm px-4 py-2 rounded shadow"
                    >
                      删除
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div v-else>
              <p class="text-gray-500">没有调整记录。</p>
            </div>
          </div>

          <!-- 添加/修改调整按钮 -->
          <div class="mt-6">
            <button 
              @click="toggleAdjustmentForm()" 
              class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-md focus:outline-none"
            >
              {{ isEditMode ? '取消编辑' : '新增调整' }}
            </button>
          </div>

          <!-- 添加/修改调整表单 -->
          <div v-if="showAdjustmentForm" class="mt-6">
            <h4 class="text-lg font-bold mb-4">添加/修改调整</h4>
            <form @submit.prevent="saveAdjustment" class="space-y-4">
              <div class="flex items-center">
                <label for="trade_type" class="w-1/4 text-sm font-medium text-gray-700">调整类型</label>
                <select v-model.number="newAdjustment.trade_type" id="trade_type" class="block w-3/4 border border-gray-300 rounded-md px-4 py-2">
                  <option :value="1">买入</option>
                  <option :value="0">卖出</option>
                </select>
              </div>

              <div class="flex items-center">
                <label for="trade_price" class="w-1/4 text-sm font-medium text-gray-700">调整价格</label>
                <input 
                  v-model.number="newAdjustment.trade_price" 
                  id="trade_price" 
                  type="number" 
                  step="0.01"
                  class="block w-3/4 border border-gray-300 rounded-md px-4 py-2"
                />
              </div>

              <div class="flex items-center">
                <label for="amount" class="w-1/4 text-sm font-medium text-gray-700">调整数量</label>
                <input 
                  v-model.number="newAdjustment.amount" 
                  id="amount" 
                  type="number" 
                  class="block w-3/4 border border-gray-300 rounded-md px-4 py-2"
                />
              </div>

              <div class="flex items-center">
                <label for="adjustment_reason" class="w-1/4 text-sm font-medium text-gray-700">调整原因</label>
                <textarea 
                  v-model="newAdjustment.adjustment_reason" 
                  id="adjustment_reason" 
                  class="block w-3/4 border border-gray-300 rounded-md px-4 py-2"
                ></textarea>
              </div>

              <div class="flex items-center">
                <label for="adjustment_date" class="w-1/4 text-sm font-medium text-gray-700">调整日期</label>
                <input 
                  v-model="newAdjustment.adjustment_date" 
                  id="adjustment_date" 
                  type="date" 
                  class="block w-3/4 border border-gray-300 rounded-md px-4 py-2"
                  required
                />
              </div>

              <div class="text-right">
                <button 
                  type="submit" 
                  class="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-md focus:outline-none"
                >
                  保存调整
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import apiClient from '@/plugins/axios';

export default {
  setup() {
    const route = useRoute();
    const tradeRecord = ref(null);
    const adjustments = ref([]);
    const loadingAdjustments = ref(false);
    const newAdjustment = ref({
      trade_record_id: route.params.id,  // 默认赋值为当前记录ID
      trade_type: 1,  // 默认买入
      trade_price: null,
      amount: null,
      adjustment_reason: '',
      adjustment_date: '',
    });
    const isEditMode = ref(false);
    const showAdjustmentForm = ref(false);
    const currentAdjustmentId = ref(null);

    // 获取交易记录
    const fetchTradeRecord = async () => {
      try {
        const response = await apiClient.get(`/trade-records/${route.params.id}`);
        if (response.status === 200 && response.data) {
          tradeRecord.value = response.data.tradeRecord;
          fetchHoldingDetails(); // 获取持仓详情
        } else {
          console.error('交易记录获取失败或返回数据不正确');
        }
      } catch (error) {
        console.error('获取交易记录失败', error);
      }
    };

    // 获取持仓详情
    const fetchHoldingDetails = async () => {
      try {
        const response = await apiClient.get(`/trade-records/${route.params.id}/holding-details`);
        if (response.status === 200 && response.data) {
          tradeRecord.value.holding_price = response.data.holding_price;
          tradeRecord.value.holding_shares = response.data.holding_shares;
          tradeRecord.value.holding_value = response.data.holding_value;
        } else {
          console.error('持仓详情获取失败或返回数据不正确', response);
        }
      } catch (error) {
        console.error('获取持仓详情失败', error);
      }
    };

    // 获取交易调整记录
    const fetchAdjustments = async () => {
      try {
        loadingAdjustments.value = true;
        const response = await apiClient.get(`/trade-records/${route.params.id}/adjustments`);
        if (response.status === 200 && Array.isArray(response.data)) {
          adjustments.value = response.data;
        } else {
          console.error('获取调整记录失败或返回格式不正确', response);
        }
      } catch (error) {
        console.error('获取交易调整记录失败', error);
      } finally {
        loadingAdjustments.value = false;
      }
    };

    // 保存交易调整（新增或编辑）
    const saveAdjustment = async () => {
      try {
        // 确保 newAdjustment 中包含 trade_record_id 和 adjustment_date
        newAdjustment.value.trade_record_id = route.params.id;
        if (!newAdjustment.value.adjustment_date) {
          alert('调整日期为必填项，请填写后再提交。');
          return;
        }

        let response;
        if (isEditMode.value) {
          // 编辑模式
          response = await apiClient.put(`/trade-records/${route.params.id}/adjustments/${currentAdjustmentId.value}`, newAdjustment.value);
        } else {
          // 新增模式
          response = await apiClient.post(`/trade-records/${route.params.id}/adjustments`, newAdjustment.value);
        }

        if (response.status === 200 || response.status === 201) {
          fetchAdjustments();  // 刷新调整记录
          resetNewAdjustment();  // 重置表单
        } else {
          console.error('保存调整失败', response);
        }
      } catch (error) {
        console.error('保存调整请求失败', error);
      }
    };

    // 删除交易调整
    const deleteAdjustment = async (adjustmentId) => {
      if (confirm('确实要删除此交易调整吗？')) {
        try {
          const response = await apiClient.delete(`/trade-records/${route.params.id}/adjustments/${adjustmentId}`);
          if (response.status === 204) {
            fetchAdjustments();
          } else {
            console.error('删除失败', response);
          }
        } catch (error) {
          console.error('删除调整失败', error);
        }
      }
    };

    // 编辑交易调整
    const editAdjustment = (adjustment) => {
      currentAdjustmentId.value = adjustment.id;
      newAdjustment.value = { ...adjustment };
      isEditMode.value = true;
      showAdjustmentForm.value = true;
    };

    // 切换表单显示和重置
    const toggleAdjustmentForm = () => {
      showAdjustmentForm.value = !showAdjustmentForm.value;
      if (!showAdjustmentForm.value) {
        resetNewAdjustment();
      }
    };

    // 重置调整表单
    const resetNewAdjustment = () => {
      newAdjustment.value = {
        trade_record_id: route.params.id,  // 重新设置 trade_record_id
        trade_type: 1,  // 默认买入
        trade_price: null,
        amount: null,
        adjustment_reason: '',
        adjustment_date: '',
      };
      isEditMode.value = false;
      currentAdjustmentId.value = null;
    };

    // 组件挂载时，获取交易记录和调整记录
    onMounted(() => {
      fetchTradeRecord();
      fetchAdjustments();
    });

    return {
      tradeRecord,
      adjustments,
      loadingAdjustments,
      newAdjustment,
      saveAdjustment,
      deleteAdjustment,
      editAdjustment,
      toggleAdjustmentForm,
      showAdjustmentForm,
      isEditMode,
    };
  },
};
</script>
