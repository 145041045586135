<template>
  <AppLayout>
    <template #header>
      <h2 class="text-xl font-semibold text-gray-800 leading-tight">Dashboard</h2>
    </template>

    <div class="flex flex-col h-full p-6">
      <div class="flex flex-row flex-1 gap-6">
        <!-- 左边豆腐块 -->
        <div class="flex-1 bg-white shadow-lg rounded-lg p-6 overflow-auto">
          <!-- 固定标题和更新日期部分 -->
          <div class="flex justify-between items-center mb-6">
            <div class="flex items-center">
              <h2 class="text-2xl font-bold text-gray-800">大牛</h2>
              <!-- 图标部分 -->
              <img src="/BigBull.png" alt="icon" class="w-6 h-6 ml-2">
            </div>
            <span class="text-red-500 font-bold">*更新日期：{{ stockDate }}</span>
          </div>

          <div v-if="errorMessage" class="text-red-500 text-center mb-4">{{ errorMessage }}</div>
          <div v-if="loading" class="text-center">正在加载中...</div>

          <!-- 表格内容 -->
          <div>
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">股票名称</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">收盘价</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">MA5</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">MA10</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">MA20</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">EMA60</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">EMA120</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="stock in stockData" :key="stock.id">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <a :href="generateTradingViewLink(stock.ts_code)" target="_blank">{{ stock.name }}</a>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">{{ stock.close }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{ stock.MA5 }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{ stock.MA10 }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{ stock.MA20 }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{ stock.EMA60 }}</td>
                  <td class="px-6 py-4 whitespace-nowrap">{{ stock.EMA120 }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="!loading && stockData.length === 0" class="text-center mt-4">没有可显示的数据。</div>
        </div>

        <!-- 右边豆腐块，内容可以根据需要填充 -->
        <div class="flex-1 bg-white shadow-lg rounded-lg p-6 overflow-auto">
          <div class="flex items-center">
            <h2 class="text-2xl font-bold text-gray-800">战绩</h2>
            <!-- 图标部分 -->
            <img src="/Record.png" alt="icon" class="w-6 h-6 ml-2">
          </div>
          <!-- 右边豆腐块的内容放置在这里 -->
          <div class="text-blue-500 text-center mt-4">内容示例，等有具体内容想法了再填充</div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import { ref, onMounted } from 'vue';
import apiClient from '@/plugins/axios';  // 确保导入了 apiClient

export default {
  setup() {
    const stockData = ref([]);
    const loading = ref(true);
    const errorMessage = ref('');
    const stockDate = ref('');  // 用于存储日期

    // 生成TradingView链接函数
    const generateTradingViewLink = (ts_code) => {
      const prefix = ts_code.endsWith('.SZ') ? 'SZSE' : ts_code.endsWith('.SH') ? 'SSE' : 'Unknown';
      const symbol = ts_code.slice(0, -3);
      return `https://cn.tradingview.com/chart/g25qQEp8/?symbol=${prefix}%3A${symbol}`;
    };

    const getStockData = async () => {
      loading.value = true;
      try {
        const response = await apiClient.get('/good-stocks', {
          withCredentials: true,
        });
        stockData.value = response.data; // 确保这里赋值正确
        if (stockData.value.length > 0) {
          stockDate.value = stockData.value[0].trade_date;  // 仅提取一次日期
        }
      } catch (error) {
        console.error('Error fetching stock data:', error);
        errorMessage.value = '无法获取股票数据，请稍后再试。';
      } finally {
        loading.value = false;
      }
    };

    onMounted(getStockData);

    return {
      stockData,
      loading,
      errorMessage,
      stockDate,
      generateTradingViewLink, // 返回生成链接的函数
    };
  },
};
</script>