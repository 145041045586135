<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-50">
    <h2 class="text-3xl font-bold text-gray-800">登录</h2>
    <form @submit.prevent="login" class="mt-6 w-full max-w-sm">
      <input 
        v-model="email" 
        type="email" 
        placeholder="邮箱" 
        required 
        class="mb-4 px-4 py-2 w-full border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <input 
        v-model="password" 
        type="password" 
        placeholder="密码" 
        required 
        class="mb-6 px-4 py-2 w-full border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <button 
        type="submit" 
        class="px-6 py-3 w-full bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        登录
      </button>
    </form>
    <router-link to="/forgot-password" class="mt-4 text-blue-500 hover:underline">
      忘记密码?
    </router-link>
  </div>
</template>

<script>
import apiClient from '@/plugins/axios';

export default {
  name: 'UserLogin',
  data() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        // 调用后端登录 API
        const response = await apiClient.post('/login', {
          email: this.email,
          password: this.password
        });

        // 保存 token 到 localStorage
        localStorage.setItem('auth_token', response.data.token);

        // 跳转到首页
        this.$router.push('/home');
      } catch (error) {
        console.error('登录失败:', error);
        alert('登录失败，请检查您的邮箱和密码');
      }
    }
  }
};
</script>

<style scoped>
.bg-gray-50 {
  background-color: #f9fafb;
}

.text-gray-800 {
  color: #2d3748;
}

.bg-blue-500 {
  background-color: #3b82f6;
}

.bg-blue-600 {
  background-color: #2563eb;
}

.text-blue-500 {
  color: #3b82f6;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.focus\:ring-2 {
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}
</style>
