<template>
  <AppLayout title="创建交易记录">
    <template #header>
      <h2 class="font-semibold text-2xl text-gray-800 leading-tight">创建交易记录</h2>
    </template>

    <div class="py-12">
      <div class="max-w-3xl mx-auto sm:px-6 lg:px-8">
        <div class="bg-white shadow-md rounded-lg p-8">
          <form @submit.prevent="submit">
                <!-- 股票选择 -->
          <div class="mb-4 flex items-center">
            <label for="stock" class="block text-sm font-medium text-gray-700 w-1/3 pr-4">股票选择</label>
            <div class="w-2/3">
              <Multiselect 
                v-model="selectedStock"  
                :options="stocks"       
                :searchable="true"
                :close-on-select="true"
                :clear-on-select="false"
                :limit="5"
                :max-height="200"
                placeholder="请输入股票名称或6位数的代码进行股票选择"
                label="name"
                track-by="stock_id"
                :custom-label="stock => `${stock.name} (${stock.symbol})`"
                class="block w-full border border-gray-300 rounded-md shadow-sm px-4 py-2 bg-white relative z-50"
                :selectLabel="'选择'"
              />
              <div v-if="form.errors?.stock_id" class="text-red-500 text-sm mt-2">{{ form.errors.stock_id }}</div>
            </div>
          </div>
       <!-- 现价 -->
            <div class="mb-4 flex items-center">
              <label for="stock_current_price" class="block text-sm font-medium text-gray-700 w-1/3 pr-4">现价</label>
              <div class="w-2/3">
                <input 
                  v-model="form.stock_current_price" 
                  id="stockPrice" 
                  class="block w-full border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed px-4 py-2" 
                  readonly
                />
                <div class="text-sm mt-2 flex space-x-2">
                  <span class="text-blue-600">
                    Open: <span class="font-bold">{{ form.open }}</span>
                  </span>
                  <span class="text-green-600">
                    | High: <span class="font-bold">{{ form.high }}</span>
                  </span>
                  <span class="text-red-600">
                    | Low: <span class="font-bold">{{ form.low }}</span>
                  </span>
                </div>
              </div>
            </div>

            <!-- 开仓价 -->
            <div class="mb-4 flex items-center">
              <label for="initial_opening_price" class="block text-sm font-medium text-gray-700 w-1/3 pr-4">开仓价</label>
              <input 
                v-model="form.initial_opening_price" 
                id="initial_opening_price" 
                class="block w-2/3 border border-gray-300 rounded-md px-4 py-2"
              />
              <div v-if="form.errors?.initial_opening_price" class="text-red-500 text-sm mt-2">{{ form.errors.initial_opening_price }}</div>
            </div>

            <!-- 止损价 -->
            <div class="mb-4 flex items-center">
              <label for="initial_stop_loss_price" class="block text-sm font-medium text-gray-700 w-1/3 pr-4">止损价</label>
              <input 
                v-model="form.initial_stop_loss_price" 
                id="initial_stop_loss_price" 
                class="block w-2/3 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed px-4 py-2"
                readonly
              />
            </div>

            <!-- 止盈价 -->
            <div class="mb-4 flex items-center">
              <label for="initial_target_profit_price" class="block text-sm font-medium text-gray-700 w-1/3 pr-4">止盈价</label>
              <input 
                v-model="form.initial_target_profit_price" 
                id="initial_target_profit_price" 
                class="block w-2/3 border border-gray-300 rounded-md px-4 py-2"
              />
              <div v-if="form.errors?.initial_target_profit_price" class="text-red-500 text-sm mt-2">{{ form.errors.initial_target_profit_price }}</div>
            </div>

            <!-- 初始盈亏比 -->
            <div class="mb-4 flex items-center">
              <label for="riskRewardRatio" class="block text-sm font-medium text-gray-700 w-1/3 pr-4">初始盈亏比</label>
              <input 
                v-model="form.riskRewardRatio" 
                id="riskRewardRatio" 
                class="block w-2/3 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed px-4 py-2" 
                readonly
              />
            </div>

            <!-- 交易原因 -->
            <div class="mb-4 flex items-center">
              <label for="reason_to_trade" class="block text-sm font-medium text-gray-700 w-1/3 pr-4">交易原因</label>
              <textarea 
                v-model="form.reason_to_trade" 
                id="reason_to_trade" 
                class="block w-2/3 border border-gray-300 rounded-md px-4 py-2"
              ></textarea>
            </div>

            <!-- 开仓日期 -->
            <div class="mb-4 flex items-center">
              <label for="opening_date" class="block text-sm font-medium text-gray-700 w-1/3 pr-4">开仓日期</label>
              <input 
                v-model="form.opening_date" 
                id="opening_date" 
                type="date" 
                class="block w-2/3 border border-gray-300 rounded-md px-4 py-2"
              />
            </div>

            <!-- 提交按钮 -->
            <div class="text-right">
              <button 
                type="submit" 
                class="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
              >提交</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Multiselect from 'vue-multiselect';
import apiClient from '@/plugins/axios';

export default {
  components: {
    Multiselect,
  },
  setup() {
    const form = ref({
      stock_id: '',
      stock_current_price: '',
      initial_opening_price: '',
      initial_stop_loss_price: '',
      initial_target_profit_price: '',
      riskRewardRatio: '',
      reason_to_trade: '',
      opening_date: new Date().toISOString().slice(0, 10),
      errors: {}
    });

    const stocks = ref([]);
    const selectedStock = ref(null);
    const maxLossRatio = 1.5;
    const maxDrawdown = 4.5;

    const fetchStocks = async () => {
    try {
      const response = await apiClient.get('/stock-prices-all');  // 假设后端路径为 '/prices'
      stocks.value = response.data.stocks || [];

      if (stocks.value.length > 0) {
        selectedStock.value = stocks.value[0]; // 默认选中第一条股票
        updateStockData(selectedStock.value);
      }
    } catch (error) {
      console.error('获取股票数据时出错:', error);
    }
  };

// 更新股票数据
const updateStockData = (stock) => {
  form.value.stock_id = stock?.stock_id || '';
  form.value.stock_current_price = stock?.close || ''; // 现价使用 close 值
  form.value.open = stock?.open || '';
  form.value.high = stock?.high || '';
  form.value.low = stock?.low || '';
  form.value.initial_opening_price = form.value.stock_current_price || '';
  calculateStopLossAndMaxShares();
};

// 监听 selectedStock 的变化，更新相关字段
watch(selectedStock, (newStock) => {
  if (newStock) {
    updateStockData(newStock);
  } else {
    form.value.stock_id = '';
    form.value.stock_current_price = '';
    form.value.open = '';
    form.value.high = '';
    form.value.low = '';
  }
});


    // 计算止损价和最大买入量
    const calculateStopLossAndMaxShares = () => {
      if (!form.value.initial_opening_price) return;

      const currentPrice = parseFloat(form.value.initial_opening_price);
      const maxLossAmount = (maxLossRatio / 100) * currentPrice;

      form.value.maxShares = Math.floor(maxLossAmount / currentPrice);
      form.value.initial_stop_loss_price = (currentPrice * (1 - maxDrawdown / 100)).toFixed(2);
    };

    const calculateRiskRewardRatio = () => {
      if (!form.value.initial_opening_price || !form.value.initial_stop_loss_price || !form.value.initial_target_profit_price) return;

      const currentPrice = parseFloat(form.value.initial_opening_price);
      const stopLossPrice = parseFloat(form.value.initial_stop_loss_price);
      const targetProfitPrice = parseFloat(form.value.initial_target_profit_price);

      const risk = currentPrice - stopLossPrice;
      const reward = targetProfitPrice - currentPrice;

      form.value.riskRewardRatio = (reward / risk).toFixed(2);
    };

    watch(() => form.value.initial_opening_price, calculateStopLossAndMaxShares);
    watch(() => form.value.initial_target_profit_price, calculateRiskRewardRatio);

     // 表单提交逻辑
     const router = useRouter();
     const submit = async () => {
      if (!form.value.stock_id) {
        console.error('股票ID未选中或为空');
        return;
      }

      const payload = {
        stock_id: form.value.stock_id,
        stock_current_price: parseFloat(form.value.stock_current_price),
        initial_opening_price: parseFloat(form.value.initial_opening_price),
        initial_stop_loss_price: parseFloat(form.value.initial_stop_loss_price),
        initial_target_profit_price: parseFloat(form.value.initial_target_profit_price),
        risk_reward_ratio: parseFloat(form.value.riskRewardRatio),
        reason_to_trade: form.value.reason_to_trade,
        opening_date: form.value.opening_date,
        closing_date: null,  // 还未平仓
        is_closed: false,  // 默认为未平仓
        final_profit: null,  // 最终盈亏
        final_thoughts: null  // 最终总结
      };

      // 打印调试信息，检查payload是否正确
      console.log('提交的数据:', payload);

      try {
        await apiClient.post('/trade-records/create', payload);
        router.push({ name: 'trade-record-index' });
      } catch (error) {
        form.value.errors = error.response?.data.errors || {};
        console.error('表单提交错误:', error);
      }
    };

    onMounted(fetchStocks);

    return {
      form,
      stocks,
      selectedStock,
      submit,
    };
  },
};
</script>

<style scoped>
.multiselect__content-wrapper {
  position: absolute;
  z-index: 50; /* Ensure this is high enough to be above other elements */
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}
</style>