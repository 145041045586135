<template>
  <AppLayout title="我的交易记录">
    <template #header>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">我的交易记录</h2>
    </template>

    <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="bg-white shadow-xl sm:rounded-lg p-6">

          <!-- 顶部部分，包括标题和按钮 -->
          <div class="flex justify-between items-center mb-6">
            <h3 class="text-lg font-medium text-gray-700">交易记录列表</h3>
            <router-link to="/trade-records/create" class="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 shadow-md">
              创建交易记录
            </router-link>
          </div>
          <!-- 交易记录列表 -->
          <div v-if="sortedTradeRecords.length > 0 && !loading" class="space-y-6">
            <div v-for="record in sortedTradeRecords" :key="record.id" class="border border-gray-200 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow ease-in-out duration-300">
           
            <!-- 第1层：股票信息和操作按钮（在一行） -->
            <div class="flex justify-between items-center mb-4">
              <h4 class="text-lg font-semibold text-gray-800">
                {{ record.stock?.name || '未知股票' }} ({{ record.stock?.symbol || '未知代码' }})
              </h4>
              <div class="flex space-x-3">
                <button @click="viewDetails(record.id)" class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg shadow">
                  查看详情
                </button>
                <button @click="deleteRecord(record.id)" class="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg shadow">
                  删除
                </button>
              </div>
            </div>

              <!-- 第2层：股票价格信息（单独一行） -->
              <div class="flex flex-wrap gap-x-4 items-center mb-2 text-sm">
                <!-- 收盘价和涨跌幅，使用大字体和颜色突出 -->
                <span :class="record.pct_chg >= 0 ? 'text-red-600 font-bold text-lg' : 'text-green-600 font-bold text-lg'">
                  收盘价: {{ record.close || '未知' }} 
                  ({{ record.pct_chg >= 0 ? '+' : '' }}{{ record.pct_chg || '未知' }})
                </span>

                <!-- 开盘价、最高价、最低价等次要数据，使用灰色和正常字号 -->
                <span class="text-gray-500 font-normal">开盘价: {{ record.open || '未知' }}</span>
                <span class="text-gray-500 font-normal">最高价: {{ record.high || '未知' }}</span>
                <span class="text-gray-500 font-normal">最低价: {{ record.low || '未知' }}</span>
                <span class="text-gray-500 font-normal">成交量: {{ record.vol || '未知' }}</span>
                <span class="text-gray-500 font-normal">成交额: {{ record.amount || '未知' }}</span>
                <span class="text-gray-500 font-normal">振幅: {{ record.amplitude ? record.amplitude.toFixed(2) : '未知' }}</span>
              </div>


              <!-- 第3层：基础信息和盈亏信息 -->
              <div class="flex">
                <!-- 3.1层：普通基础信息展示 -->
                <div class="flex-1 space-y-4">
                  <!-- 3.1.1层 -->
                  <div class="grid grid-cols-4 gap-4">
                    <div class="flex flex-col">
                      <span class="text-gray-600">开仓日期</span>
                      <span class="font-semibold text-gray-800">{{ record.opening_date || '未知日期' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">开仓价格</span>
                      <span class="font-semibold text-gray-800">{{ record.initial_opening_price || '未知价格' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">止损价格</span>
                      <span class="font-semibold text-gray-800">{{ record.initial_stop_loss_price || '未知价格' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">止盈价格</span>
                      <span class="font-semibold text-gray-800">{{ record.initial_target_profit_price || '未知价格' }}</span>
                    </div>
                  </div>
                  <!-- 3.1.2层 -->
                  <div class="grid grid-cols-4 gap-4">
                    <div class="flex flex-col">
                      <span class="text-gray-600">盈亏比</span>
                      <span class="font-semibold text-gray-800">{{ record.risk_reward_ratio || '尚未计算' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">持仓成本</span>
                      <span class="font-semibold text-gray-800">{{ record.holding_price !== undefined ? record.holding_price : '未知价格' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">持股数量</span>
                      <span class="font-semibold text-gray-800">{{ record.holding_shares !== undefined ? record.holding_shares : '未知数量' }}</span>
                    </div>
                    <div class="flex flex-col">
                      <span class="text-gray-600">持仓市值</span>
                      <span class="font-semibold text-gray-800">{{ record.holding_value !== undefined ? record.holding_value : '未知金额' }}</span>
                    </div>
                  </div>
                </div>

                <!-- 3.2层：盈亏信息展示 -->
                <div class="flex items-center justify-center bg-green-100 p-4 ml-4 w-32 h-full rounded-lg shadow-md">
                  <span class="text-xl font-semibold text-green-700" :class="record.final_profit < 0 ? 'text-red-600' : 'text-green-600'">
                    {{ record.final_profit !== undefined ? record.final_profit : '尚未确定' }}
                  </span>
                </div>
              </div>

            </div>
          </div>

          <!-- 如果没有交易记录 -->
          <div v-else-if="!loading" class="mt-4">
            <p class="text-gray-500 mb-4">尚未创建任何交易记录。</p>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import apiClient from '@/plugins/axios';

export default {
  setup() {
    const router = useRouter();

    const tradeRecords = ref([]);  // 用于存储交易记录（静态）
    const stockPrices = ref({});   // 用于存储股票价格（动态）
    const loading = ref(true);     // 控制加载状态

    // 第一次加载时获取交易记录
    const fetchTradeRecords = async () => {
      try {
        const tradeResponse = await apiClient.get('/trade-records');
        tradeRecords.value = tradeResponse.data.tradeRecords || [];
      } catch (error) {
        console.error('获取交易记录出错:', error);
      } finally {
        loading.value = false;
      }
    };

    // 获取股票价格
    const fetchStockPrices = async () => {
      if (tradeRecords.value.length > 0) {
        const stockIds = tradeRecords.value.map(record => record.stock_id);

        try {
          const priceResponse = await apiClient.get('/stock-prices-by-ids', {
            params: {
              stock_ids: stockIds
            }
          });

          // 将价格数据存储为以 stock_id 为键的对象
          stockPrices.value = priceResponse.data.stocks.reduce((acc, stock) => {
            acc[stock.stock_id] = stock;
            return acc;
          }, {});
        } catch (error) {
          console.error('获取股票价格出错:', error);
        }
      }
    };

    // 获取持仓详情
    const fetchHoldingDetails = async (tradeRecordId) => {
      try {
        const response = await apiClient.get(`/trade-records/${tradeRecordId}/holding-details`);
        if (response.status === 200 && response.data) {
          const index = tradeRecords.value.findIndex(record => record.id === tradeRecordId);
          if (index !== -1) {
            const record = tradeRecords.value[index];
            const totalSell = parseFloat(response.data.total_sell) || 0;
            const totalBuy = parseFloat(response.data.total_buy) || 0;
            const holdingShares = parseFloat(response.data.holding_shares) || 0;
            const holdingPrice = parseFloat(stockPrices.value[record.stock_id]?.close || response.data.holding_price) || 0;

            record.holding_price = holdingPrice;
            record.holding_shares = holdingShares;
            record.holding_value = holdingShares * holdingPrice;

            // 从后端返回的交易调整数据计算最终盈亏
            if (holdingShares > 0) {
              record.final_profit = totalSell - totalBuy + (holdingShares * holdingPrice);
            } else {
              record.final_profit = totalSell - totalBuy;
            }

            // 确保数据响应性更新
            tradeRecords.value = [...tradeRecords.value];
          }
        } else {
          console.error('持仓详情获取失败或返回数据不正确', response);
        }
      } catch (error) {
        console.error('获取持仓详情失败', error);
      }
    };

    // 页面加载时调用
    onMounted(() => {
      fetchTradeRecords().then(() => {
        fetchStockPrices();   // 确保 tradeRecords 加载后再加载股票价格
        tradeRecords.value.forEach(record => fetchHoldingDetails(record.id)); // 获取每个交易记录的持仓详情
      });
    });

    // 合并交易记录和股票价格
    const sortedTradeRecords = computed(() => {
      return tradeRecords.value.map(record => {
        const stockPrice = stockPrices.value[record.stock_id] || {};
        return {
          ...record,
          ...stockPrice  // 合并动态价格数据
        };
      }).sort((a, b) => (a.is_closed ? 1 : 0) - (b.is_closed ? 1 : 0));
    });

    const viewDetails = (id) => {
      router.push({ name: 'trade-record-show', params: { id } });
    };

    const deleteRecord = async (id) => {
      if (confirm('确定要删除这条交易记录吗？')) {
        try {
          loading.value = true;
          const response = await apiClient.delete(`/trade-records/${id}`);
          if (response.status === 200 || response.status === 204) {
            tradeRecords.value = tradeRecords.value.filter(record => record.id !== id);
            alert('交易记录已成功删除');
          } else {
            alert('删除失败，请稍后重试');
          }
        } catch (error) {
          console.error('删除交易记录出错:', error);
          alert('删除失败，请稍后重试');
        } finally {
          loading.value = false;
        }
      }
    };

    return {
      sortedTradeRecords,
      viewDetails,
      deleteRecord,
      loading,
    };
  },
};
</script>