<template>
    <div class="min-h-screen bg-gray-100">
      <div class="bg-white p-4 rounded-lg shadow-lg w-full text-center">
        <!-- 交易原则部分 -->
        <div class="bg-gray-50 p-12 rounded-lg shadow-md">
          <div class="text-left text-gray-700 text-lg leading-relaxed space-y-8">
            
            <div>
              <p class="font-bold text-xl mb-2">严格遵守止损原则</p>
              <div class="grid grid-cols-2">
                <p><strong>原则：</strong>每笔交易都必须设定止损点，并严格执行。</p>
                <p><strong>目的：</strong>控制潜在亏损，避免情绪化的决策。</p>
              </div>
            </div>
  
            <div>
              <p class="font-bold text-xl mb-2">保持资金管理</p>
              <div class="grid grid-cols-2">
                <p><strong>原则：</strong>每次交易的风险金额应控制在总账户资金的2-5%以内。</p>
                <p><strong>目的：</strong>通过分散风险，减少单一错误带来的资金大幅回撤。</p>
              </div>
            </div>
  
            <div>
              <p class="font-bold text-xl mb-2">跟随趋势，不逆势操作</p>
              <div class="grid grid-cols-2">
                <p><strong>原则：</strong>交易顺应市场趋势，不试图预测或逆转市场趋势。</p>
                <p><strong>目的：</strong>避免在趋势反转或盘整期中遭受过大的损失。</p>
              </div>
            </div>
  
            <div>
              <p class="font-bold text-xl mb-2">保持冷静，避免情绪化操作</p>
              <div class="grid grid-cols-2">
                <p><strong>原则：</strong>不因恐惧或贪婪而改变计划，保持冷静执行策略。</p>
                <p><strong>目的：</strong>情绪化操作往往导致不合理的亏损。</p>
              </div>
            </div>
  
            <div>
              <p class="font-bold text-xl mb-2">定期复盘，优化策略</p>
              <div class="grid grid-cols-2">
                <p><strong>原则：</strong>每月或每季度复盘交易，分析得失，调整策略。</p>
                <p><strong>目的：</strong>持续改进交易方法，提升成功率。</p>
              </div>
            </div>
  
            <div>
              <p class="font-bold text-xl mb-2">管理预期，保持耐心</p>
              <div class="grid grid-cols-2">
                <p><strong>原则：</strong>不要期望一夜暴富，理解复利和长期积累的力量。</p>
                <p><strong>目的：</strong>耐心是成为成功交易者的关键，避免短期的急功近利。</p>
              </div>
            </div>
  
            <div>
              <p class="font-bold text-xl mb-2">避免全仓交易</p>
              <div class="grid grid-cols-2">
                <p><strong>原则：</strong>永远不要将所有资金投入一只股票或一次交易。</p>
                <p><strong>目的：</strong>分散投资，避免单一股票带来的巨大风险。</p>
              </div>
            </div>
  
            <div>
              <p class="font-bold text-xl mb-2">保持学习和研究</p>
              <div class="grid grid-cols-2">
                <p><strong>原则：</strong>市场不断变化，持续学习和研究新趋势和方法。</p>
                <p><strong>目的：</strong>保持对市场的敏锐感知，不断提升自己的专业能力。</p>
              </div>
            </div>
  
            <div>
              <p class="font-bold text-xl mb-2">计划交易，交易计划</p>
              <div class="grid grid-cols-2">
                <p><strong>原则：</strong>在交易前设定明确的计划，进场、出场以及止盈、止损点。</p>
                <p><strong>目的：</strong>计划性的交易能避免盲目跟风和临时决策失误。</p>
              </div>
            </div>
  
            <div>
              <p class="font-bold text-xl mb-2">保持风险回报比合理</p>
              <div class="grid grid-cols-2">
                <p><strong>原则：</strong>确保每次交易的潜在收益与风险比不低于2:1。</p>
                <p><strong>目的：</strong>在成功率相近的情况下，提高总体盈利水平。</p>
              </div>
            </div>
  
          </div>
        </div>
        <!-- 交易原则部分结束 -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TradePrinciplesPage',
  };
  </script>
  