import './assets/tailwind.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from './plugins/axios'; // 引入axios实例

const app = createApp(App);

app.config.globalProperties.$axios = axios; // 将axios实例注入到Vue的全局属性中

app.use(router).mount('#app');
