<template>
  <nav class="bg-white border-b border-gray-100">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center py-4"> <!-- 使用 py-4 代替 h-16，调节上下内边距 -->
        <!-- 左侧菜单 -->
        <div class="flex items-center">
          <div class="shrink-0">
            <a href="/">
              <img class="h-8 w-auto" src="/logo.png" alt="Logo">
            </a>
          </div>
          <div class="hidden sm:flex sm:ml-10 space-x-8">
            <router-link to="/home" class="text-gray-500 hover:text-gray-700">首页</router-link>
            <router-link to="/trade-records" class="text-gray-500 hover:text-gray-700">我的交易</router-link>
            <router-link to="/user-positions" class="text-gray-500 hover:text-gray-700">仓位管理</router-link>
            <router-link to="/trading-principles" class="text-gray-500 hover:text-gray-700">交易原则</router-link>
          </div>
        </div>

        <!-- 右侧用户菜单 -->
        <div class="hidden sm:flex sm:items-center">
          <div class="relative ml-3">
            <button @click="toggleMenu" class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out">
              <img class="h-8 w-8 rounded-full" src="/user-avatar.png" alt="User Avatar">
            </button>
            <div v-if="open" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
              <router-link to="/profile" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">修改密码</router-link>
              <router-link to="/logout" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">退出登录</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleMenu() {
      this.open = !this.open;
    }
  }
};
</script>
